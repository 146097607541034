<template>
  <div>
    <article>
      <div class="container">
        <div v-if="!isWIP">
          <navbar></navbar>
        </div>
        <router-view />
      </div>
      <footer-comp></footer-comp>
    </article>
  </div>
</template>

<script>
import Navbar from './components/layout/Navbar.vue';
import FooterComp from './components/layout/FooterComp.vue';

export default {
  components: {
    Navbar,
    FooterComp,
  },
  computed: {
    isWIP() {
      return this.$store.getters.isWIP;
    },
  },
};
</script>

<style lang="scss">
@import "../node_modules/bootstrap/dist/css/bootstrap.css";

html,
body {
  width: 100%;
  height: 100%;
}

article {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
}

body {
  background: #ffffff;
}

#main-container {
  background: #ffffff;
  min-height: 90vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@media (max-width: 560px) {
  #main-container {
    margin: 0px;
    min-height: 100vh;
  }
}
</style>
