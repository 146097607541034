import { createRouter, createWebHashHistory } from 'vue-router';

import Home from '../views/Home.vue';
import EquipoProfesional from '../views/EquipoProfesional.vue';
import Servicios from '../views/Servicios.vue';
import Human from '../views/Human.vue';
import Contenidos from '../views/Contenidos.vue';
import WIP from '../views/Wip.vue';

import store from '../store/index';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/equipo-profesional',
    name: 'Equipo Profesional',
    component: EquipoProfesional,
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: Servicios,
  },
  {
    path: '/human',
    name: 'Human',
    component: Human,
  },
  {
    path: '/contenidos',
    name: 'Contenidos',
    component: Contenidos,
  },
  {
    // work in progress
    path: '/wip',
    name: 'WIP',
    component: WIP,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// navigation Guard for Work in Progress
router.beforeEach((to, _, next) => {
  if (to.name !== 'WIP' && store.getters.isWIP) {
    next('/wip');
    return;
  }

  if (!store.getters.isWIP && to.name === 'WIP') {
    next('/');
    return;
  }

  next();
});

export default router;
