<template>
  <footer class="d-flex justify-content-center">
    <div class="d-flex flex-column contact align-items-center">
      <div class="social__icons m-3">
        <div>
          <a href="https://wa.me/523327080811" target="_blank">
            <i class="fab fa-whatsapp social__icon"></i>
          </a>
          <a href="https://www.facebook.com/IMETSC" target="_blank">
            <i class="fab fa-facebook-f social__icon"></i>
          </a>
          <a href="https://www.instagram.com/imetscmx/" target="_blank"><i class="fab fa-instagram social__icon"></i></a>
          <a href="#">
            <i class="fab fa-youtube social__icon"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
footer {
  background: #294551;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.contact {
  font-size: 0.9rem;
  color: white;
}

.social__icons {
  margin: 0 100px;
  width: 100%;
}

.social__icon {
  font-size: 28px;
  margin: 0 8px;
}

a {
  color: white;
}

a:hover {
  color: yellow;
}
</style>
