import { createStore } from 'vuex';

export default createStore({
  state: {
    projectName: 'Instituto Mexicano de Trabajo Social Clínico',
    isWIP: true,
  },
  getters: {
    isWIP(state) {
      return state.isWIP;
    },
    projectName(state) {
      return state.projectName;
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
