<template>
  <nav class="navbar navbar-expand-lg navbar-light w-100">
    <img src="@/assets/imetsc.png" alt="" width="200" @click="linkBrand"/>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <router-link active-class="active" class="nav-link" to="/" tag="h2">Home</router-link>
        </li>
        <li class="nav-item active">
          <router-link active-class="active" class="nav-link" to="/equipo-profesional" tag="h2"
            >Equipo Profesional</router-link
          >
        </li>
        <li class="nav-item active">
          <router-link active-class="active" class="nav-link" to="/servicios" tag="h2"
            >Servicios</router-link
          >
        </li>
        <li class="nav-item active">
          <router-link active-class="active" class="nav-link" to="/human" tag="h2"
            >Human</router-link
          >
        </li>
        <li class="nav-item active">
          <router-link active-class="active" class="nav-link" to="/contenidos" tag="h2"
            >Contenidos</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    linkBrand() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss">
  .active {
    color: blue !important;
  }
</style>
