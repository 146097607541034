<template>
  <div class="mt-3 h-100">
    <div class="d-flex flex-column align-items-center h-100">
      <img class="img-fluid" id="logo" src="@/assets/imetsc.png" />
      <img class="img-fluid mt-3" id="wip" src="@/assets/wip.png" />
      <h1>Sitio en construcción</h1>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    projectName() {
      return this.$store.getters.projectName;
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  font-size: 2.5rem;
}
#logo {
  width: 200px;
}
@media (max-width: 540px) {
  h1 {
    font-size: 1.5rem;
  }
}
</style>
